import React, { useCallback } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import twitter_logo from "../assets/image/logo/twitter-logo.svg";
import axios from "axios";

function Footer() {
  const onClickTerms = useCallback(async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_EQHUB_SERVER_URL +
        "/api/v1/whisper-service/terms-and-conditions"
    );
    window.open(data[0].content);
  }, []);

  const onClickPrivacy = useCallback(async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_EQHUB_SERVER_URL +
        "/api/v1/whisper-service/privacy-policies"
    );
    window.open(data[0].content);
  }, []);

  return (
    <footer className={"container footer"}>
      <div className={"footer-container"}>
        <div className={"FLEX_ROW"}>
          <div className={"footer-section"}>
            <div className={"footer-section-title"}>Company</div>
            <ul className={"footer-section-list"}>
              <li className={"footer-section-list-item"}>
                <Link to={"/about"} className={"footer-section-list-item-link"}>
                  About us
                </Link>
              </li>
              <li className={"footer-section-list-item"}>
                <button
                  className={"footer-section-list-item-link"}
                  onClick={onClickTerms}
                >
                  Terms
                </button>
              </li>
              <li className={"footer-section-list-item"}>
                <button
                  className={"footer-section-list-item-link"}
                  onClick={onClickPrivacy}
                >
                  Privacy Policy
                </button>
              </li>
            </ul>
          </div>
          <div className={"footer-section"}>
            <div className={"footer-section-title"}>Products</div>
            <ul className={"footer-section-list"}>
              <li className={"footer-section-list-item"}>
                <Link
                  to={process.env.REACT_APP_EQBR_URL}
                  className={"footer-section-list-item-link"}
                >
                  Equilibrium
                </Link>
              </li>
              <li className={"footer-section-list-item"}>
                <Link
                  to={process.env.REACT_APP_EQHUB_URL}
                  className={"footer-section-list-item-link"}
                >
                  EQ Hub
                </Link>
              </li>
            </ul>
          </div>
          <div className={"footer-section section-medium"}>
            <div className={"footer-section-title"}>CS</div>
            <ul className={"footer-section-list"}>
              <li className={"footer-section-list-item"}>
                <a
                  className={"footer-section-list-item-link"}
                  href="mailto:eqbr_admin@eqbr.net"
                >
                  eqbr_admin@eqbr.net
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={"footer-section section-mobile"}>
          <div className={"footer-section-title"}>CS</div>
          <ul className={"footer-section-list"}>
            <li className={"footer-section-list-item"}>
              <a
                className={"footer-section-list-item-link"}
                href="mailto:eqbr_admin@eqbr.net"
              >
                eqbr_admin@eqbr.net
              </a>
              {/* <Link className={'footer-section-list-item-link'}>eqbr_admin@eqbr.net</Link> */}
            </li>
          </ul>
        </div>
        <div className={"footer-social"}>
          <a
            href="https://twitter.com/Whisper_MSG"
            target={"_blank"}
            rel={"noreferrer"}
          >
            <img className={"footer-social-icon"} src={twitter_logo} alt="" />
          </a>
        </div>
      </div>
      <div className={"footer-copyright"}>
        Copyright © 2023. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
